import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import firebase from "../firebase";
import store from "../store";
import { LoadUserAction } from "../store/auth/auth.action";
import { IUser } from "../store/auth/auth.dto";
import logger from "./logger";

export const setAuthToken = (token: String) => {
  if (token) {
    api.defaults.headers.common["Authorization"] = token;
  } else {
    delete api.defaults.headers.common["Authorization"];
  }
};

const api = axios.create({
  baseURL:
    window.location.hostname.includes("herokuapp") ||
    window.location.hostname.includes("localhost")
      ? "/api/"
      : "https://skirnir.herokuapp.com/api/",
});

api.interceptors.request.use(
  (config: AxiosRequestConfig): AxiosRequestConfig => {
    const CONTEXT = `api.instance :: REQ ${config.method}`;
    logger.debug(config.url as string, CONTEXT, false, config);
    return config;
  }
);

api.interceptors.response.use(
  (res: AxiosResponse): AxiosResponse => {
    const CONTEXT = `api.instance :: RES ${res.config.method} /${res.config.url}`;
    logger.debug(res.data, CONTEXT, false, res);
    return res;
  },
  async (err: AxiosError) => {
    const CONTEXT = `api.instance :: RES ${err.config.method} /${err.config.url}`;
    logger.warn(err.message, CONTEXT, false, err);
    const origReq = err.config;
    // If no response, malformed return or cancel; pass through
    if (!err.response) {
      logger.debug("Err has no response", CONTEXT, true, {
        err,
        res: err.response,
      });
      return Promise.reject(err);
    }
    // If not a 401, refreshing won't help.
    if (err.response.status !== 401) {
      logger.debug("Not a 401", CONTEXT);
      return Promise.reject(err);
    }

    // If a 401 on auth, I probably just failed to fetch a token.
    if (origReq.url === "auth") {
      logger.debug("401 on auth; assuming I just tried to refresh", CONTEXT);
      return Promise.reject(err);
    }

    // If a 401, my token might have expired; try refreshing it
    logger.success("Attempting refresh", CONTEXT);
    const u = firebase.auth().currentUser;
    if (!u) {
      logger.warn("No current user", CONTEXT);
      return Promise.reject(err);
    }
    logger.debug({ u }, CONTEXT + " // currentUser");
    try {
      const token = await u.getIdToken(/*forceRefresh */ true);
      logger.success(token, CONTEXT + " // token");
      setAuthToken(token);
      const api_user: IUser = (await api.get("auth")).data;
      logger.success({ api_user }, CONTEXT + " // api_user");
      store.dispatch(LoadUserAction(api_user, token));
      // Set auth token
      origReq.headers["Authorization"] = token;
      return api(origReq);
    } catch (error) {
      logger.error(error, CONTEXT, false, error);
      return Promise.reject(err);
    }
  }
);

export default api;
