import React, { useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { ThemeColour } from "../../common/theme.types";
import { RootState } from "../../store/root-reducer";
import Spinner from "../atomic/Spinner";
import ToggleSwitch from "../atomic/ToggleSwitch";
import DeleteCheckedButton from "../items/DeleteCheckedButton";
import ItemList from "../items/ItemList";
import TitleOnlyItemForm from "../items/TitleOnlyItemForm";
import Login from "./Login";

const Main = ({ auth }: Props) => {
  const [showChecked, setShowChecked] = useState<boolean>(true);

  return (
    <main className="container-fluid p-4">
      {auth.loading ? (
        <Spinner variant={ThemeColour.Primary} />
      ) : auth.user ? (
        <div className="items">
          <div className="row">
            <TitleOnlyItemForm />
            <ToggleSwitch
              name="Show checked?"
              value={showChecked}
              onToggle={() => setShowChecked(!showChecked)}
              variant={ThemeColour.Success}
              rounded
            />

            <div className="actions ml-auto">
              <DeleteCheckedButton />
            </div>
          </div>
          <ItemList showChecked={showChecked} />
        </div>
      ) : (
        <Login />
      )}
    </main>
  );
};

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
});

const connector = connect(mapStateToProps, {});

type Props = ConnectedProps<typeof connector> & {};

export default connector(Main);
