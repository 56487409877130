import api from "../../utils/api.instance";
import logger from "../../utils/logger";
import { AppThunk } from "../app-thunk";
import {
  Item,
  ItemActionTypes,
  ItemData,
  ItemDataQuestionable,
  ItemId,
  ITEMS_ERROR,
  ITEMS_GET,
  ITEM_ADDED,
  ITEM_REMOVED,
  ITEM_UPDATED,
} from "./items.types";

/**
 * Action types
 * This level of abstraction adds a bunch of extra code BUT also strong typing
 */

export const GetItemsAction = (items: Item[]): ItemActionTypes => ({
  type: ITEMS_GET,
  payload: items,
});

export const AddItemAction = (item: Item): ItemActionTypes => ({
  type: ITEM_ADDED,
  payload: item,
});

export const RemoveItemAction = (itemId: ItemId): ItemActionTypes => ({
  type: ITEM_REMOVED,
  payload: itemId,
});

export const UpdateItemAction = (
  itemId: ItemId,
  data: ItemDataQuestionable
): ItemActionTypes => {
  return {
    type: ITEM_UPDATED,
    payload: { data, _id: itemId },
  };
};

export const ItemErrorAction = (error: Error): ItemActionTypes => ({
  type: ITEMS_ERROR,
  payload: error,
});

/**
 * Actions
 */

export const getItems = (): AppThunk => async (dispatch) => {
  logger.trace("Getting items", "items.action.ts :: getItems");
  try {
    const items = (await api.get("items/")).data;
    dispatch(GetItemsAction(items));
  } catch (error) {
    logger.warn(error.message, "items.action.ts :: getItems", true, { error });
    dispatch(ItemErrorAction(error));
  }
};

export const createItem = (data: ItemData): AppThunk => async (dispatch) => {
  logger.trace(data.title, "items.action.ts :: addItem", false, { data });
  try {
    // TODO: Implement this with a temporary uuid, then replace it if the api call succeeds
    const item = (await api.post("items/", data)).data;
    dispatch(AddItemAction(item));
  } catch (error) {
    logger.warn(error.message, "items.action.ts :: getItems", true, { error });
    dispatch(ItemErrorAction(error));
  }
};

export const deleteItem = (item_id: ItemId): AppThunk => async (dispatch) => {
  logger.trace(item_id, "items.action.ts :: deleteItem");
  try {
    dispatch(RemoveItemAction(item_id));
    await api.delete(`items/${item_id}`);
    // TODO check if api call succeeds, undo edit if not
    // dispatch(RemoveItemAction(item_id));
  } catch (error) {
    logger.warn(error.message, "items.action.ts :: getItems", true, { error });
    dispatch(ItemErrorAction(error));
  }
};

export const toggleItem = (item: Item): AppThunk => async (dispatch) => {
  logger.trace(item.title, "items.action.ts :: toggleItem", false, { item });
  try {
    dispatch(UpdateItemAction(item._id, { ...item, checked: !item.checked }));
    const I = (
      await api.put(`items/${item._id}`, {
        checked: !item.checked,
      })
    ).data as ItemDataQuestionable;
    logger.debug({ I }, "items.action.ts :: toggleItem");
    // TODO check if api call succeeds, undo edit if not
    // dispatch(UpdateItemAction(item._id, I));
  } catch (error) {
    logger.warn(error.message, "items.action.ts :: toggleitems", true, {
      error,
    });
    dispatch(ItemErrorAction(error));
  }
};

export const setOrder = (item: Item, to: number): AppThunk => async (
  dispatch
) => {
  logger.trace({ item, to }, "items.actions.ts :: placeItemBefore");
  dispatch(UpdateItemAction(item._id, { ...item, order: to }));
  try {
    const I = (
      await api.put(`items/${item._id}`, {
        order: to,
      })
    ).data as ItemDataQuestionable;
    logger.debug({ I }, "items.action.ts :: setOrder");
    // TODO check if api call succeeds, undo edit if not
    // dispatch(UpdateItemAction(item._id, I));
  } catch (error) {
    logger.warn(error.message, "items.action.ts :: placeItemBefore", true, {
      error,
    });
    dispatch(ItemErrorAction(error));
  }
};

export const updateItem = (
  item_id: ItemId,
  data: ItemDataQuestionable
): AppThunk => async (dispatch) => {
  logger.trace(item_id, "items.action.ts :: updateItem", false, {
    item_id,
    data,
  });
  try {
    dispatch(UpdateItemAction(item_id, data));
    const I = (await api.put(`items/${item_id}`, data))
      .data as ItemDataQuestionable;
    logger.debug({ I }, "items.action.ts :: updateItem");

    // TODO check if api call succeeds, undo edit if not
    // dispatch(UpdateItemAction(item_id, I));
  } catch (error) {
    logger.warn(error.message, "items.action.ts :: getItems", true, { error });
    dispatch(ItemErrorAction(error));
  }
};
