import React from "react";
import { ThemeColour } from "../../common/theme.types";

interface Props {
  value: boolean;
  onToggle: () => void;
  variant?: ThemeColour;
  name?: string;
  rounded?: boolean;
}

const ToggleSwitch = ({
  value,
  onToggle,
  variant = ThemeColour.Primary,
  name,
  rounded,
}: Props) => {
  return name ? (
    <div className="toggle-switch-with-label">
      <span className="label">{name}</span>
      <label className="toggle-switch">
        <input type="checkbox" checked={value} onChange={onToggle} />
        <span
          className={`slider slider-${variant}${rounded ? " round" : ""}`}
        ></span>
      </label>
    </div>
  ) : (
    <label className="toggle-switch">
      <input type="checkbox" checked={value} onChange={onToggle} />
      <span
        className={`slider slider-${variant}${rounded ? " round" : ""}`}
      ></span>
    </label>
  );
};

export default ToggleSwitch;
