import React, { Fragment, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { ThemeColour } from "../../common/theme.types";
import { getItems, setOrder } from "../../store/items/items.actions";
import { Item } from "../../store/items/items.types";
import { RootState } from "../../store/root-reducer";
import logger from "../../utils/logger";
import Spinner from "../atomic/Spinner";
import ItemView from "./ItemView";

enum DIR {
  UP = "up",
  DOWN = "down",
}

const ItemList = ({ items, getItems, setOrder, showChecked }: Props) => {
  useEffect(() => {
    getItems();
  }, [getItems]);

  const [picked, setPicked] = useState<Item | null>(null);
  const [hover, setHover] = useState<{ i: number; d: DIR } | null>(null);
  const [myItems, setMyItems] = useState<Item[]>([]);

  useEffect(() => {
    setMyItems(
      items.items.filter((i) => !i.checked).sort((a, b) => a.order - b.order)
    );
  }, [items.items]);

  const onPickup = (target: Item) => {
    logger.info({ target }, "onDragStart");
    setPicked(target);
  };
  const onDropByOrder = (order: number) => {
    if (picked === null || picked.order === order) return;
    setOrder(picked, order);
    setPicked(null);
    setHover(null);
  };
  const onDrop = (target: Item) => {
    logger.info({ target }, "onDrop");
    if (picked === null || target === picked) return;
    const A = items.items.sort((a, b) => a.order - b.order);
    const i = A.indexOf(target);
    if (i === -1) {
      logger.warn(
        "Something really weird happened!",
        "ItemList :: onDrop",
        false,
        { i, items: A }
      );
    } else if (i === 0) {
      logger.info("Dropped on first item ");
      setOrder(picked, target.order / 2);
    } else {
      logger.success("Dropped on " + i + "th item");
      setOrder(picked, (A[i].order + A[i - 1].order) / 2);
    }
    setPicked(null);
    setHover(null);
  };
  const allowDrop = (e: React.DragEvent) => {
    e.preventDefault();
  };

  const onDragEnter = (e: React.DragEvent, t: Item["order"]) => {
    if (picked && picked.order !== t) setHover({ i: t, d: DIR.UP });
  };
  const onDragLeave = (e: React.DragEvent, t: Item["order"]) => {
    // if (hover === t)
    setHover(null);
  };

  return items.loading ? (
    <Spinner variant={ThemeColour.Primary} />
  ) : (
    <div className="item-list">
      {myItems.map((item, idx) => (
        <Fragment>
          <div
            className={`item-spacer${
              hover && hover.i === item.order ? ` hover-${hover.d}` : ""
            }`}
            onDrop={(e) => onDrop(item)}
            onDragOver={allowDrop}
            onDragEnter={(e) => onDragEnter(e, item.order)}
            onDragLeave={(e) => onDragLeave(e, item.order)}
          />
          <ItemView
            item={item}
            key={idx}
            hovered={hover && item.order === hover.i ? `hover-${hover.d}` : ""}
            onDragStart={onPickup}
          />
        </Fragment>
      ))}
      {myItems.length > 0 && (
        <div
          className={`item-spacer${
            hover && hover.i > myItems[myItems.length - 1].order
              ? ` hover-${hover.d}`
              : ""
          }`}
          onDrop={(e) => onDropByOrder(myItems[myItems.length - 1].order + 1)}
          onDragOver={allowDrop}
          onDragEnter={(e) =>
            onDragEnter(e, myItems[myItems.length - 1].order + 1)
          }
          onDragLeave={(e) =>
            onDragLeave(e, myItems[myItems.length - 1].order + 1)
          }
        />
      )}
      {showChecked && <hr className="my-4" />}
      {showChecked && (
        <div className="checked-items">
          {items.items
            .filter((i) => i.checked)
            .sort((a, b) => a.order - b.order)
            .map((item, idx) => (
              <ItemView
                item={item}
                key={idx}
                hovered={
                  hover && item.order === hover.i ? `hover-${hover.d}` : ""
                }
              />
            ))}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  items: state.items,
});

const connector = connect(mapStateToProps, {
  getItems,
  setOrder,
});

type Props = ConnectedProps<typeof connector> & {
  showChecked: boolean;
};

export default connector(ItemList);
