import firebase from "firebase";
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCeD1gCMHJsi7_as5_K7cgXVJ_N8zsVsIc",
  authDomain: "vanaheim-westling.firebaseapp.com",
  databaseURL: "https://vanaheim-westling.firebaseio.com",
  projectId: "vanaheim-westling",
  storageBucket: "vanaheim-westling.appspot.com",
  messagingSenderId: "377570493727",
  appId: "1:377570493727:web:bea3fee5c969a1e9e89c2a",
  measurementId: "G-DRR6SSJ9M0",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();
export const googleProvider = new firebase.auth.GoogleAuthProvider();
export const passwordProvider = new firebase.auth.EmailAuthProvider();
export const microsoftProvider = new firebase.auth.OAuthProvider(
  "microsoft.com"
);

export const auth = firebase.auth();
export default firebase;
