import React, { useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Item, ItemDataQuestionable } from "../../store/items/items.types";
import { RootState } from "../../store/root-reducer";
import {
  deleteItem,
  toggleItem,
  updateItem,
} from "../../store/items/items.actions";

const ItemView = ({
  item,
  toggleItem,
  deleteItem,
  updateItem,
  onDragStart,
  hovered,
}: Props) => {
  const { _id, title, description, checked } = item;

  const [editable, setEditable] = useState<boolean>(false);

  const [formData, setFormData] = useState<ItemDataQuestionable>({
    title,
    description,
  });

  const onSubmit = () => {
    updateItem(_id, formData);
    setEditable(false);
    setFormData({ title, description });
  };

  return (
    <div
      className={`item${checked ? " item-checked" : ""}${
        hovered ? ` ${hovered}` : ""
      }`}
    >
      {editable ? (
        <form className="content" onSubmit={onSubmit}>
          <input
            type="text"
            name="title"
            value={formData.title}
            onChange={(e) => {
              setFormData({ ...formData, title: e.target.value });
            }}
          />
        </form>
      ) : (
        <div
          className="content"
          onClick={() => toggleItem(item)}
          draggable="true"
          onDragStart={() => (onDragStart ? onDragStart(item) : null)}
        >
          <div className="title">{title}</div>
          {description && <div className="desc">{description}</div>}
        </div>
      )}
      <div className="actions">
        {/* <span className="btn-reorder" >
          <i className="fas fa-arrows-alt-v"></i>
        </span> */}
        {editable && (
          <button className="btn btn-outline-success" onClick={onSubmit}>
            <i className="fas fa-save"></i>
          </button>
        )}
        <span
          className="btn-dropdown"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i className="fas fa-ellipsis-v"></i>
        </span>
        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <span
            className="dropdown-item"
            onClick={() => setEditable(!editable)}
          >
            Edit
          </span>
          <span
            className="dropdown-item text-danger"
            onClick={() => deleteItem(_id)}
          >
            Delete
          </span>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  items: state.items,
});

const connector = connect(mapStateToProps, {
  deleteItem,
  toggleItem,
  updateItem,
});
type Props = ConnectedProps<typeof connector> & {
  item: Item;
  hovered?: string;
  onDragStart?: (target: Item) => void;
};

export default connector(ItemView);
