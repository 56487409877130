import { UserId } from "../auth/auth.types";

/**
 * Type defintions
 */
export const ITEMS_GET = "ITEMS_GET";
export const ITEM_ADDED = "ITEM_ADDED";
export const ITEM_UPDATED = "ITEM_UPDATED";
export const ITEM_REMOVED = "ITEM_REMOVED";
export const ITEMS_CLEAR = "ITEMS_CLEAR";
export const ITEMS_ERROR = "ITEMS_ERROR";

/**
 * Types
 */

export type ItemId = string;

export interface ItemData {
  title: string;
  description?: string;
  checked?: boolean;
  requirements?: Item[];
  due?: Date;
  order: number;
  status?: string;
}

export interface ItemDataQuestionable {
  title?: string;
  description?: string;
  checked?: boolean;
  requirements?: Item[];
  due?: Date;
  order?: number;
  status?: string;
}

// The extra fields are the things that come from the backend
export type Item = ItemData & {
  _id: ItemId;
  user: UserId;
  date: Date;
};

export interface ItemState {
  items: Item[];
  loading: boolean;
  error: Error | null;
}

export interface UpdateItemPayload {
  data: ItemDataQuestionable;
  _id: ItemId;
}

// /**
//  * Action type interfaces
//  */
export interface GetItemsActionType {
  type: typeof ITEMS_GET;
  payload: Item[];
}

export interface AddItemActionType {
  type: typeof ITEM_ADDED;
  payload: Item;
}

export interface RemoveItemActionType {
  type: typeof ITEM_REMOVED;
  payload: Item["_id"];
}

export interface UpdateItemActionType {
  type: typeof ITEM_UPDATED;
  payload: UpdateItemPayload;
}

export interface ItemErrorActionType {
  type: typeof ITEMS_ERROR;
  payload: Error;
}

// /**
//  * Final export
//  */
export type ItemActionTypes =
  | GetItemsActionType
  | AddItemActionType
  | RemoveItemActionType
  | UpdateItemActionType
  | ItemErrorActionType;
