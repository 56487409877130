import {
  Item,
  ItemActionTypes,
  ItemId,
  ItemState,
  ITEMS_ERROR,
  ITEMS_GET,
  ITEM_ADDED,
  ITEM_REMOVED,
  ITEM_UPDATED,
  UpdateItemPayload,
} from "./items.types";
import logger from "../../utils/logger";

const initialState: ItemState = {
  items: [],
  loading: true,
  error: null,
};

const itemReducer = (
  state: ItemState = initialState,
  action: ItemActionTypes
): ItemState => {
  const { type, payload } = action;
  switch (type) {
    case ITEMS_GET:
      return { ...state, items: payload as Item[], loading: false };
    case ITEM_ADDED:
      return {
        ...state,
        items: [...state.items, payload as Item],
        loading: false,
      };
    case ITEM_REMOVED:
      return {
        ...state,
        items: state.items.filter((i) => i._id !== (payload as ItemId)),
      };
    case ITEM_UPDATED:
      const p = payload as UpdateItemPayload;
      logger.info({ p });
      return {
        ...state,
        items: state.items.map((i) =>
          i._id === p._id ? { ...i, ...p.data } : i
        ),
        loading: false,
      };
    case ITEMS_ERROR:
      return { ...state, error: payload as Error };
    default:
      return state;
  }
};

export default itemReducer;
