import React, { useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
  loginWithPassword,
  registerPassword,
  loginWithGoogle,
  loginWithMicrosoft,
} from "../../store/auth/auth.action";

const Login = ({
  loginWithPassword,
  registerPassword,
  loginWithGoogle,
  loginWithMicrosoft,
}: Props) => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const onSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    loginWithPassword(email, password);
  };

  const onLogin = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    loginWithPassword(email, password);
  };

  const onGLogin = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    loginWithGoogle();
  };

  const onMSLogin = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    loginWithMicrosoft();
  };

  const onRegister = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    registerPassword(email, password);
  };

  return (
    <div className="jumbotron">
      <form className="form login-form" onSubmit={onSubmit}>
        <div className="input-group mb-3">
          <div className="input-group-prepend">
            <span className="input-group-text" id="email_input">
              @
            </span>
          </div>
          <input
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="form-control"
            placeholder="Email address"
            aria-label="Email address"
            aria-describedby="email_input"
          />
        </div>
        <div className="input-group mb-3">
          <div className="input-group-prepend">
            <span className="input-group-text" id="pw_input">
              Password
            </span>
          </div>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="form-control"
            placeholder="Password"
            aria-label="Password"
            aria-describedby="pw_input"
          />
        </div>
        <button className="btn btn-primary" onClick={onLogin}>
          Log in
        </button>
        <button className="btn btn-secondary" onClick={onRegister}>
          Register
        </button>
        <button className="btn btn-success" onClick={onGLogin}>
          Login with Google
        </button>
        <button className="btn btn-success" onClick={onMSLogin}>
          Login with Microsoft
        </button>
      </form>
    </div>
  );
};

const mapStateToProps = null;

const connector = connect(mapStateToProps, {
  loginWithPassword,
  registerPassword,
  loginWithGoogle,
  loginWithMicrosoft,
});

type Props = ConnectedProps<typeof connector> & {};

export default connector(Login);
