import { ThemeColour } from "../../common/theme.types";

/**
 * Type defintions
 */
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

/**
 * Enums
 */

/**
 * Types
 */

export interface AlertPayload {
  msg: string;
  alertType: ThemeColour;
  id: string;
}
// If I need to add fields, add to AO's
export type AlertObject = AlertPayload;

// AlertState is just an array of AlertObjects
export type AlertState = AlertObject[];

// /**
//  * Action type interfaces
//  */
export interface SetAlertAction {
  type: typeof SET_ALERT;
  payload: AlertPayload;
}

export interface RemoveAlertAction {
  type: typeof REMOVE_ALERT;
  payload: string;
}

// /**
//  * Final export
//  */
export type AlertActionTypes = SetAlertAction | RemoveAlertAction;
