import { UserRoles } from "../../common/backend.types";

/**
 * Type defintions
 */
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGOUT = "LOGOUT";

export type UserId = string;

/**
 * Types
 */

export interface UserObject {
  email: string;
  given_name?: string;
  family_name?: string;
  phone?: string;
  roles: [UserRoles];
  id: UserId;
  firebase_data?: {
    picture?: string;
    email_verified?: Boolean;
    provider?: string;
  };
}
// If I need to add fields, add to AO's
export type AuthPayload = {
  user: UserObject;
  token: string;
};

// AuthState is just an array of UserObjects
export interface AuthState {
  token: string;
  user: UserObject | null;
  loading: boolean;
  error: Error | null;
}

// /**
//  * Action type interfaces
//  */
export interface LoadUserAction {
  type: typeof USER_LOADED;
  payload: AuthPayload;
}

export interface LogoutAction {
  type: typeof LOGOUT;
  payload: string;
}

export interface AuthErrorAction {
  type: typeof AUTH_ERROR;
  payload: Error;
}

// /**
//  * Final export
//  */
export type AuthActionTypes = LoadUserAction | LogoutAction | AuthErrorAction;
