import React, { Fragment } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
  logout,
  linkToGoogle,
  linkToMicrosoft,
} from "../../store/auth/auth.action";
import { RootState } from "../../store/root-reducer";
import logo from "../../logo_light.svg";

const Navbar = ({
  logout,
  linkToGoogle,
  linkToMicrosoft,
  auth,
  title,
}: Props) => {
  return (
    <nav className="navbar navbar-expand navbar-light bg-light">
      <span className="navbar-brand">
        <img
          src={logo}
          width="36"
          height="36"
          className="d-inline-block align-top mr-2"
          alt="Skirnir logo"
        />
        {title}
      </span>
      <ul className="navbar-nav ml-auto">
        {auth.user &&
          auth.user.firebase_data &&
          auth.user.firebase_data.provider === "password" &&
          !auth.user.firebase_data.email_verified && (
            <li className="nav-item text-danger">
              Your email has not been verified.
            </li>
          )}
        {auth.user && (
          <Fragment>
            <li className="nav-item">
              {auth.user.firebase_data && auth.user.firebase_data.picture && (
                <img
                  src={auth.user.firebase_data.picture}
                  width="36"
                  height="36"
                  className="d-inline-block align-top mr-4"
                  alt="user logo"
                />
              )}
            </li>
            <li className="nav-item">
              <button onClick={linkToGoogle} className="btn btn-light">
                <i className="fab fa-google"></i>
              </button>
            </li>
            <li className="nav-item">
              <button onClick={linkToMicrosoft} className="btn btn-light">
                <i className="fab fa-microsoft"></i>
              </button>
            </li>
            <li className="nav-item">
              <button
                className="btn btn-outline-danger"
                onClick={() => logout()}
              >
                <i className="fas fa-sign-out-alt"></i>
              </button>
            </li>
          </Fragment>
        )}
      </ul>
    </nav>
  );
};

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
});

const connector = connect(mapStateToProps, {
  logout,
  linkToGoogle,
  linkToMicrosoft,
});

type Props = ConnectedProps<typeof connector> & {
  title: string;
};

export default connector(Navbar);
