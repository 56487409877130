import { combineReducers } from "redux";
import alertReducer from "./alert/alert.reducer";
import authReducer from "./auth/auth.reducer";
import itemReducer from "./items/item.reducer";

export const rootReducer = combineReducers({
  alert: alertReducer,
  auth: authReducer,
  items: itemReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
