import React from "react";
import { ThemeColour } from "../../common/theme.types";

const Spinner = (props: Props) => {
  return (
    <div className={`spinner-border text-${props.variant}`} role="status">
      <span className="sr-only">Loading...</span>
    </div>
  );
};

type Props = {
  variant: ThemeColour;
};

export default Spinner;
