import { v4 as uuid } from "uuid";
import { ThemeColour } from "../../common/theme.types";
import { AppThunk } from "../app-thunk";
import { AlertActionTypes, REMOVE_ALERT, SET_ALERT } from "./alert.types";

/**
 * Action types
 */

// This extra level of abstraction adds a bunch of extra code BUT also strong typing
export const SetAlertAction = (
  msg: string,
  alertType: ThemeColour,
  id: string
): AlertActionTypes => {
  return {
    type: SET_ALERT,
    payload: { msg, alertType, id },
  };
};

const RemoveAlertAction = (id: string): AlertActionTypes => {
  return {
    type: REMOVE_ALERT,
    payload: id,
  };
};

/**
 * Actions
 */

/**
 * setAlert adds a new alert for a temporary time
 * @param msg Message to report to user
 * @param ThemeColour Type of alert
 * @param timeout Duration in ms to keep the alert around
 */
export const setAlert = (
  msg: string,
  alertType: ThemeColour = ThemeColour.Primary,
  timeout: number = 5000
): AppThunk => async (dispatch) => {
  const id = uuid();
  dispatch(SetAlertAction(msg, alertType, id));

  setTimeout(() => dispatch(RemoveAlertAction(id)), timeout);
};
