import firebase, {
  auth,
  googleProvider,
  microsoftProvider,
} from "../../firebase";
import api, { setAuthToken } from "../../utils/api.instance";
import logger from "../../utils/logger";
import { AppThunk } from "../app-thunk";
import { IUser } from "./auth.dto";
import { AuthActionTypes, AUTH_ERROR, LOGOUT, USER_LOADED } from "./auth.types";

/**
 * Action types
 */

// This extra level of abstraction adds a bunch of extra code BUT also strong typing
export const LoadUserAction = (user: IUser, token: string): AuthActionTypes => {
  return {
    type: USER_LOADED,
    payload: { user, token },
  };
};

const AuthErrorAction = (e: Error): AuthActionTypes => {
  return {
    type: AUTH_ERROR,
    payload: e,
  };
};

const LogoutAction = (): AuthActionTypes => {
  return {
    type: LOGOUT,
    payload: "",
  };
};

/**
 * Actions
 */

export const loginWithGoogle = (): AppThunk => async (dispatch) => {
  logger.trace("Logging in...", "auth.action.ts :: loginWithGoogle");
  try {
    const res = await auth.signInWithPopup(googleProvider);
    console.log(res);
    // Now fetch the user object from the backend
    dispatch(loadUser());
  } catch (error) {
    // TODO handle better
    logger.warn(
      error.response ? error.response.data.message : error.message,
      "auth.action.ts :: loginWithGoogle",
      true,
      {
        error,
      }
    );
    dispatch(AuthErrorAction(error));
  }
};

export const loginWithMicrosoft = (): AppThunk => async (dispatch) => {
  logger.trace("Logging in...", "auth.action.ts :: loginWithMicrosoft");
  try {
    const res = await auth.signInWithPopup(microsoftProvider);
    console.log(res);
    // Now fetch the user object from the backend
    dispatch(loadUser());
  } catch (error) {
    logger.info(error.message, "auth.action.ts :: loginWithMicrosoft", true, {
      error,
    });
    dispatch(AuthErrorAction(error));
  }
};

export const linkToMicrosoft = (): AppThunk => async (dispatch) => {
  logger.trace("Linking to Microsoft", "auth.action.ts :: loginWithMicrosoft");
  try {
    const u = auth.currentUser;
    if (u) {
      const res = await u.linkWithPopup(microsoftProvider);
      logger.success(res, "auth.action.ts :: loginWithMicrosoft");
    } else throw new Error("Not logged in!");
  } catch (e) {
    logger.info(e.message, "auth.action.ts :: loginWithMicrosoft", true, {
      e,
    });
    dispatch(AuthErrorAction(e));
  }
};

export const linkToGoogle = (): AppThunk => async (dispatch) => {
  logger.trace("Linking to Google", "auth.action.ts :: loginWithGoogle");
  try {
    const u = auth.currentUser;
    if (u) {
      const res = await u.linkWithPopup(googleProvider);
      logger.success(res, "auth.action.ts :: loginWithGoogle");
    } else throw new Error("Not logged in!");
  } catch (e) {
    logger.info(e.message, "auth.action.ts :: loginWithGoogle", true, {
      e,
    });
    dispatch(AuthErrorAction(e));
  }
};

export const loginWithPassword = (
  email: string,
  password: string
): AppThunk => async (dispatch) => {
  logger.trace("Logging in...", "auth.action.ts :: login");
  try {
    const res = await auth.signInWithEmailAndPassword(email, password);
    console.log(res);
    // Now fetch the user object from the backend
    dispatch(loadUser());
  } catch (error) {
    logger.info(error.message, "auth.action.ts :: loginWithPassword", true, {
      error,
    });
    dispatch(AuthErrorAction(error));
  }
};

export const registerPassword = (
  email: string,
  password: string
): AppThunk => async (dispatch) => {
  logger.trace("Logging in...", "auth.action.ts :: login");
  try {
    const res = await auth.createUserWithEmailAndPassword(email, password);
    console.log(res);

    // Now fetch the user object from the backend
    dispatch(loadUser());
  } catch (error) {
    //   if (error.code === "auth/email-already-in-use") {
    logger.info(error.message, "auth.action.ts :: loginWithPassword", true, {
      error,
    });
    dispatch(AuthErrorAction(error));
  }
};

export const loadUser = (): AppThunk => async (dispatch) => {
  try {
    let token: string = "";
    firebase.auth().onAuthStateChanged(
      async function (user) {
        if (user) {
          if (!user.emailVerified) {
            logger.info("Sending email verification message");
            await user.sendEmailVerification();
          }
          token = await user.getIdToken(/* forceRefresh */ true);
          console.log(token);
          setAuthToken(token);
          try {
            const api_user: IUser = (await api.get("auth")).data;
            dispatch(LoadUserAction(api_user, token));
          } catch (error) {
            logger.warn(
              error.response ? error.response.data.message : error.message,
              "auth.action.ts :: loadUser",
              true,
              {
                error,
              }
            );
            dispatch(AuthErrorAction(error));
          }
        } else {
          dispatch(AuthErrorAction(new Error("FirebaseAuth failed")));
        }
      },
      (error) => {
        logger.warn(error, "loadUser", false);
        dispatch(AuthErrorAction(new Error(error.message)));
      }
    );
  } catch (error) {
    dispatch(AuthErrorAction(error));
  }
};

export const logout = (): AppThunk => async (dispatch) => {
  logger.trace("Logging out...", "auth.action.ts :: logout");
  try {
    await firebase.auth().signOut();
    // Now fetch the user object from the backend
    dispatch(LogoutAction());
  } catch (error) {
    dispatch(AuthErrorAction(error));
  }
};
