import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../store/root-reducer";
import { deleteItem } from "../../store/items/items.actions";
import logger from "../../utils/logger";

const DeleteCheckedButton = ({ deleteItem, items: { items } }: Props) => {
  const deleteChecked = () => {
    items.forEach((i) => {
      logger.info(i);
      if (i.checked) deleteItem(i._id);
    });
  };

  return (
    <button
      onClick={deleteChecked}
      className="btn btn-sm ml-auto btn-outline-danger"
    >
      Delete checked
    </button>
  );
};

const mapStateToProps = (state: RootState) => ({
  items: state.items,
});

const connector = connect(mapStateToProps, { deleteItem });
type Props = ConnectedProps<typeof connector> & {};

export default connector(DeleteCheckedButton);
