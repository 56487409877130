// The Logger is here to help!
import { ThemeColour } from "../common/theme.types";

// TODO: Pass non-object messages to Alert better

/**
 * Types
 */

type AlertFuncType = (
  msg: string,
  alertType: ThemeColour,
  timeout?: number
) => void;

type ContentType = string | object;
type DataType = string | object | null;

export enum LOG_LEVEL {
  ALL = -1,
  TRACE = 2,
  DEBUG = 5,
  INFO = 15,
  SUCCESS = 20,
  WARN = 25,
  ERROR = 35,
  CRITICAL = 45,
  NONE = 55,
}

export type LogItem = {
  ts: Date;
  content: ContentType;
  name?: string;
  data?: DataType;
};

export interface Log {
  trace: LogItem[];
  debug: LogItem[];
  info: LogItem[];
  success: LogItem[];
  warn: LogItem[];
  error: LogItem[];
  critical: LogItem[];
}
/**
 * Setups
 */

export const setAlertFunc = (alertFunc: AlertFuncType) => {
  // When we're more comfortable with our code base, we can turn down ALL to e.g. INFO; for now I want the trace though
  createLogger(alertFunc, LOG_LEVEL.ALL, LOG_LEVEL.ALL);
};

let alertFunc = (msg: string, alertType: ThemeColour, timeout?: number) => {};

let logs: Log = {
  trace: [],
  debug: [],
  info: [],
  success: [],
  warn: [],
  error: [],
  critical: [],
};

let level = LOG_LEVEL.INFO;
let levelSave = LOG_LEVEL.ALL;

export const createLogger = (
  _alertFunc: AlertFuncType,
  minLevel: LOG_LEVEL = LOG_LEVEL.INFO,
  minSave: LOG_LEVEL = LOG_LEVEL.ALL
) => {
  // minLevel - nothing below this will be reported to the developer console
  // Set up my "global variables"
  alertFunc = _alertFunc;
  try {
    level = minLevel; // Should come in as a string.
    levelSave = minSave; // Should come in as a string.
  } catch {
    // You did the wrong thing...
    warn(
      `Incompatible level detected in arguments (${minLevel}, ${minSave}); use one from the list [${Object.keys(
        LOG_LEVEL
      ).join(",")}]`
    );
  }
};

export const history = () => {
  return logs;
};

/**
 * Formatting
 */

export const ftrace = (
  content: ContentType,
  _name: string = "",
  _alert: boolean = false,
  _data: DataType = null
) => {
  const name = typeof _name === "string" ? _name : "";
  const useAlert = typeof _name === "boolean" ? _name : _alert;

  if (levelSave <= LOG_LEVEL.TRACE)
    logs.trace.push({ ts: new Date(), name, content, data: _data });
  if (level > LOG_LEVEL.TRACE) return [];
  if (useAlert) {
    // Note: if you use _alert, you should send string content or alertFunc needs to be ready for not-string-content.
    alertFunc(JSON.stringify(content), ThemeColour.Light);
  }
  if (typeof content !== "string")
    return [`%c TRACE${name ? ` [${name}]` : ""}:`, "color: #959595", content];
  else
    return [`%c TRACE${name ? ` [${name}]` : ""}: ` + content, "color:#959595"];
};

export const fdebug = (
  content: ContentType,
  _name: string = "",
  _alert: boolean = false,
  _data: DataType = null
) => {
  const name = typeof _name === "string" ? _name : "";
  const useAlert = typeof _name === "boolean" ? _name : _alert;

  if (useAlert) {
    // Always alert, even if the level is too low.
    // Note: if you use _alert, you should send string content or alertFunc needs to be ready for not-string-content.
    alertFunc(JSON.stringify(content), ThemeColour.Dark);
  }
  if (levelSave <= LOG_LEVEL.DEBUG)
    logs.debug.push({ ts: new Date(), name, content, data: _data });
  if (level > LOG_LEVEL.DEBUG) return [];
  if (typeof content !== "string")
    return [`%c DEBUG${name ? ` [${name}]` : ""}:`, "color: #656565", content];
  else
    return [`%c DEBUG${name ? ` [${name}]` : ""}: ` + content, "color:#656565"];
};

export const finfo = (
  content: ContentType,
  _name: string = "",
  _alert: boolean = false,
  _data: DataType = null
) => {
  const name = typeof _name === "string" ? _name : "";
  const useAlert = typeof _name === "boolean" ? _name : _alert;

  if (useAlert) {
    // Always alert, even if the level is too low.
    // Note: if you use _alert, you should send string content...
    alertFunc(JSON.stringify(content), ThemeColour.Primary);
  }
  if (levelSave <= LOG_LEVEL.INFO)
    logs.info.push({ ts: new Date(), name, content, data: _data });
  if (level > LOG_LEVEL.INFO) return [];
  if (typeof content !== "string")
    return [`%c INFO${name ? ` [${name}]` : ""}:`, "color: #0000bb", content];
  else
    return [`%c INFO${name ? ` [${name}]` : ""}: ` + content, "color: #0000bb"];
};

export const fsuccess = (
  content: ContentType,
  _name: string = "",
  _alert: boolean = false,
  _data: DataType = null
) => {
  const name = typeof _name === "string" ? _name : "";
  const useAlert = typeof _name === "boolean" ? _name : _alert;
  if (useAlert) {
    // Note: if you use _alert, you should send string content...
    alertFunc(JSON.stringify(content), ThemeColour.Success);
  }

  if (levelSave <= LOG_LEVEL.SUCCESS)
    logs.success.push({ ts: new Date(), name, content, data: _data });
  if (level > LOG_LEVEL.SUCCESS) return [];
  if (typeof content !== "string")
    return [
      `%c SUCCESS${name ? ` [${name}]` : ""}:`,
      "color: #00bb00",
      content,
    ];
  else
    return [
      `%c SUCCESS${name ? ` [${name}]` : ""}: ` + content,
      "color: #00bb00",
    ];
};

export const fwarn = (
  content: ContentType,
  _name: string = "",
  _alert: boolean = false,
  _data: DataType = null
) => {
  const name = typeof _name === "string" ? _name : "";
  const useAlert = typeof _name === "boolean" ? _name : _alert;
  if (useAlert) {
    // Note: if you use _alert, you should send string content...
    alertFunc(JSON.stringify(content), ThemeColour.Warning);
  }

  if (levelSave <= LOG_LEVEL.WARN)
    logs.warn.push({ ts: new Date(), name, content, data: _data });
  if (level > LOG_LEVEL.WARN) return [];

  if (typeof content !== "string")
    return [`%c WARN${name ? ` [${name}]` : ""}:`, "color: #f3722c", content];
  else
    return [`%c WARN${name ? ` [${name}]` : ""}: ` + content, "color: #f3722c"];
};

export const ferror = (
  content: ContentType,
  _name: string = "",
  _alert: boolean = false,
  _data: DataType = null
) => {
  const name = typeof _name === "string" ? _name : "";
  const useAlert = typeof _name === "boolean" ? _name : _alert;
  if (useAlert) {
    // Note: if you use _alert, you should send string content...
    alertFunc(JSON.stringify(content), ThemeColour.Danger);
  }

  if (levelSave <= LOG_LEVEL.ERROR)
    logs.error.push({ ts: new Date(), name, content, data: _data });
  if (level > LOG_LEVEL.ERROR) return [];

  if (typeof content !== "string")
    return [
      `%c ERROR${name ? ` [${name}]` : ""}:`,
      "color: #000; font-weight: bold",
      content,
    ];
  else
    return [
      `%c ERROR${name ? ` [${name}]` : ""}: ` + content,
      "color: #000; font-weight: bold",
    ];
};

export const fcritical = (
  content: ContentType,
  _name: string = "",
  _alert: boolean = false,
  _data: DataType = null
) => {
  const name = typeof _name === "string" ? _name : "";
  const useAlert = typeof _name === "boolean" ? _name : _alert;

  if (useAlert) {
    // Note: if you use _alert, you should send string content...
    alertFunc(JSON.stringify(content), ThemeColour.Critical);
  }

  if (levelSave <= LOG_LEVEL.CRITICAL)
    logs.critical.push({ ts: new Date(), name, content, data: _data });
  if (level > LOG_LEVEL.CRITICAL) return [];

  if (typeof content !== "string")
    return [
      `%c CRITICAL${name ? ` [${name}]` : ""}:`,
      "color: #ff0000; background: #ddd; font-weight: bold",
      content,
    ];
  else
    return [
      `%c CRITICAL${name ? ` [${name}]` : ""}: ` + content,
      "color: #ff0000; background: #ddd; font-weight: bold",
    ];
};

/**
 * Methods
 */

export const trace = (
  content: ContentType,
  name?: string,
  _alert?: boolean,
  data?: DataType
) => {
  const m = ftrace(content, name, _alert, data);
  if (m && m.length > 0) console.debug(...m);
};
export const debug = (
  content: ContentType,
  name?: string,
  _alert?: boolean,
  data?: DataType
) => {
  const m = fdebug(content, name, _alert, data);
  if (m && m.length > 0) console.debug(...m);
};
export const info = (
  content: ContentType,
  name?: string,
  _alert?: boolean,
  data?: DataType
) => {
  const m = finfo(content, name, _alert, data);
  if (m && m.length > 0) console.info(...m);
};
export const success = (
  content: ContentType,
  name?: string,
  _alert?: boolean,
  data?: DataType
) => {
  const m = fsuccess(content, name, _alert, data);
  if (m && m.length > 0) console.info(...m);
};
export const warn = (
  content: ContentType,
  name?: string,
  _alert?: boolean,
  data?: DataType
) => {
  const m = fwarn(content, name, _alert, data);
  if (m && m.length > 0) console.warn(...m);
};
export const error = (
  content: ContentType,
  name?: string,
  _alert?: boolean,
  data?: DataType
) => {
  const m = ferror(content, name, _alert, data);
  if (m && m.length > 0) console.error(...m);
};

export const critical = (
  content: ContentType,
  name?: string,
  _alert?: boolean,
  data?: DataType
) => {
  const m = fcritical(content, name, _alert, data);
  if (m && m.length > 0) console.error(...m);
};

const logger = {
  history,
  ftrace,
  fdebug,
  finfo,
  fsuccess,
  fwarn,
  ferror,
  trace,
  debug,
  info,
  success,
  warn,
  error,
  critical,
};
export default logger;
