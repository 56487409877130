import React, { useEffect } from "react";
import { Provider } from "react-redux";
import "./App.css";
import { ThemeColour } from "./common/theme.types";
import AlertContainer from "./components/layout/AlertContainer";
import Main from "./components/layout/Main";
import Navbar from "./components/layout/Navbar";
import "./css/index.min.css";
import store from "./store";
import { setAlert } from "./store/alert/alert.actions";
import { loadUser } from "./store/auth/auth.action";
import Logger, { setAlertFunc } from "./utils/logger";

declare global {
  interface Window {
    Logger: any;
  }
}

window.Logger = window.Logger || {};

const App = () => {
  useEffect(() => {
    setAlertFunc((m: string, a: ThemeColour, t?: number) =>
      store.dispatch(setAlert(m, a, t) as any)
    );
    window.Logger = Logger;
    store.dispatch(loadUser() as any);
    // setAuthToken(localStorage.token);
  }, []);

  return (
    <Provider store={store}>
      <Navbar title="Skirnir" />
      <Main />
      <AlertContainer />
      {/* <Footer /> */}
    </Provider>
  );
};

export default App;
